export class UpdateInfoRequest {
  constructor(
    public country: string,
    public city: string,
    public area: string,
    public address_line1: string
  ) {}
}

interface UpdateItem {
  value: string;
  actionRequired: boolean;
}

export interface UpdateInfoResponse {
  country: UpdateItem;
  city: UpdateItem;
  area: UpdateItem;
  address_line1: UpdateItem;
}
