import React from "react";
import classes from "./check-button.module.css";

interface Props {
  isChecked: boolean;
  handleChange: (name: string, checked: boolean) => void;
  label: string;
  id: string;
}

const CheckButton = (props: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.handleChange(props.label, e.target.checked);
  };
  return (
    <div>
      <label
        className={
          classes.checkBtn +
          " poppins " +
          (props.isChecked ? classes.checked : "")
        }
        htmlFor={props.id}
      >
        {props.label}
      </label>
      <input
        className="btn-check"
        type="checkbox"
        id={props.id}
        checked={props.isChecked}
        onChange={onChange}
        name={props.label}
        autoComplete="off"
      />
    </div>
  );
};
export default CheckButton;
