import React from "react";
import classes from "./text-area.module.css";

interface Props {
  title: string;
  value: string;
  setValue: (value: string) => void;
  setValidValue: (valid: boolean) => void;
}

const TextArea = (props: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value) props.setValidValue(true);
    else props.setValidValue(false);
    props.setValue(e.target.value);
  };
  return (
    <div className={classes.textAreaContainer}>
      <p className={classes.text}>{props.title}</p>
      <textarea
        value={props.value}
        onChange={onChange}
        className={classes.textArea}
        rows={3}
      ></textarea>
    </div>
  );
};
export default TextArea;
