import React from 'react';
import logo from './logo.png';

export interface ILogoProps {
    width: string;
    height: string;
}

export const FlextockLogo: React.FC<ILogoProps> = (props: ILogoProps) => {
    return (
        <>
            <img alt="logo" src={logo} width={props.width} height={props.height}></img>
        </>
    );
};
