export class FeedbackRequest {
  constructor(
    public isHappy: boolean,
    public orderIssues: string[],
    public courierIssues: string[]
  ) {}
}

export interface FeedbackResponse {
  detail?: string;
}
