import React, {
  ChangeEvent,
  ChangeEventHandler,
  MouseEvent,
  MouseEventHandler,
} from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import classes from "./auto-complete-input.module.css";
import textFieldClasses from "../text-field/text-field.module.css";

interface IAutoCompleteInputProps {
  title: string;
  placeHolder: string;
  isErrored: boolean;
  autoCompleteList: any[];

  dropdownSearchPlaceholder: string;

  /** The field name of the object attribute */
  displayFieldName: string;

  setValue: any;
  value: string | undefined;
  validationError: string;
}

const filter = (query: string, fieldName: string, array: any[]) => {
  let filtered = array.filter((value) =>
    value[fieldName].toLowerCase().includes(query.toLowerCase())
  );
  return filtered;
};

export const DropDownInput: React.FC<IAutoCompleteInputProps> = (
  props: IAutoCompleteInputProps
) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const wrapperRef = useRef<HTMLDivElement>(
    document.getElementById(props.placeHolder) as HTMLDivElement
  );

  const [filteredArray, setFilteredArray] = useState<any[]>([]);

  useEffect(() => {
    setFilteredArray(props.autoCompleteList);
  }, [props.autoCompleteList]);

  const textChangesHandler: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (!showMenu) {
      setShowMenu(true);
    }
    setFilteredArray(
      filter(event.target.value, props.displayFieldName, props.autoCompleteList)
    );
  };

  const onChooseItemClick: MouseEventHandler<HTMLInputElement> = (
    event: MouseEvent<HTMLInputElement>
  ) => {
    toggleShowMenu();
  };

  const selectItem: MouseEventHandler<HTMLInputElement> = (
    event: MouseEvent<HTMLInputElement>
  ) => {
    const name = event.currentTarget.innerHTML;
    const item = props.autoCompleteList.filter(
      (item) => item[props.displayFieldName] === name
    )[0];
    props.setValue(item);
    setShowMenu(false);
  };

  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event: globalThis.MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div
      style={{ width: "100%", position: "relative" }}
      id={props.placeHolder}
      ref={wrapperRef}
    >
      <p className={`${textFieldClasses.title} poppins`}>{props.title}</p>
      {props.validationError && (
        <p className={`${textFieldClasses.errorText} poppins`}>
          {props.validationError}
        </p>
      )}
      <div
        onClick={onChooseItemClick}
        className={`${classes.dropDownChosenItem} ` + (props.value ? classes.notErroredOutline: classes.isErroredOutline )}
      >
        <p
          style={{ margin: "0", color: props.value ? "black" : "gray" }}
          className="prevent-select poppins"
        >
          {props.value ? props.value : props.placeHolder }
        </p>
      </div>
      {showMenu && (
        <div className={classes.dropdownMenu}>
          <div style={{ padding: "4px 8px" }}>
            <input
              id={`${props.placeHolder}_input`}
              onChange={textChangesHandler}
              className={classes.searchField}
              placeholder={props.dropdownSearchPlaceholder}
              autoComplete="off"
              type="text"
            />
          </div>
          {filteredArray.map((element: any, index) => {
            return (
              <div
                key={index}
                className={classes.dropDownElement}
                onClick={selectItem}
              >
                {element[props.displayFieldName]}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
