import { ILocaleStrings } from "./locale-strings.interface";

export const arabicLocaleStrings: ILocaleStrings = {
  wrongDataTitle: "تصحيح المعلومات",
  correctAddressDataHeader:
    "من فضلك صحح المعلومات في الخانات ذات اللون الأحمر لتسهيل عملية التوصيل",
  countryInputHeader: "البلد",
  cityInputHeader: "المدينة",
  areaInputHeader: "المنطقة",
  addressInputHeader: "من فضلك أدخل عنوانك بالتفصيل",
  countryErrorText: "من فضلك اختر البلد",
  cityErrorText: "من فضلك اختر المدينة",
  areaErrorText: "من فضلك اختر المنطقة",
  addressErrorText: "عنوانك يجب أن يكون ٣ كلمات فأكثر",
  submit: "إرسال",
  orderNumber: "طلبية رقم",
  brandName: "الماركة",
  searchCountryPlaceHolder: "البحث عن بلد",
  searchCityPlaceHolder: "البحث عن مدينة",
  searchAreaPlaceHolder: "البحث عن منطقة",
  countryInputPlaceHolder: "اختر بلدك...",
  cityInputPlaceHolder: "اختر مدينتك...",
  areaInputPlaceHolder: "اختر منطقتك...",
  addressInputPlaceHolder: "أدخل عنوانك...",
  yourDataIsSavedNotifier: "تم حفظ بياناتك بنجاح",
  couldntUpdateErrorText: "فشلت محاولة حفظ بياناتك",
  noInternetConnectionErrorText: "من فضلك تحقق من اتصالك بالإنترنت.",
  youAlreadyUploadedYourDataNotifier: "تم بالفعل حفظ بياناتك.",

  /**
   * Feedback
   */
  fbTitle: "أعطنا تقييمك",
  fbAreYouHappy: "هل انت سعيد بعد الشراء من ",
  fbHappy: "سعيد",
  fbUnhappy: "غير سعيد",
  fbOrderIssues: "إذا كان لديك أي مشاكل متعلقة بالطلب ، يرجى الاختيار هنا",
  fbCourierIssues: "إذا كان لديك أي مشاكل تتعلق بالتوصيل ، يرجى الاختيار هنا",
  fbOtherIssue: "اكتب هنا مشكلتك من فضلك",
  fbSubmit: "إرسال",
  fbSmileThanks: "شكرا لتقييمك",
  fbSmileSubmitted: "لقد قدمت لنا بالفعل تقييمك",
  fbModalTitle: "فشلت محاولة إرسال تقييمك",
  fbModalInternet: "من فضلك تحقق من اتصالك بالإنترنت.",
  fbErrorAreYouHappy: "الرجاء تحديد سعيد أو غير سعيد",
  fbErrorSelectIssue: "الرجاء تحديد مشكلة",
  fbErrorOtherIssue: "الرجاء كتابة مشكلتك",
  fbOther: "مشكلة اخرى",

  /**
   * General
   */
  questionMark: "؟",
};
