import { SmileAction, SMILE } from "../actions/smile-actions.interface";
import { ISmileState } from "../states/smile.state";

const intitialState: ISmileState = {
  smile: false,
  description: '',
};

export const smileReducer = (prevState = intitialState, action: SmileAction) => {
  switch (action.type) {
    case SMILE:
      return {
        ...prevState,
        smile: action.smile,
        description: action.description,
      };
    default:
      return prevState;
  }
};
