import React from "react";
import classes from "./is-happy.module.css";
import smileyIcon from "./smiley.svg";
import sadIcon from "./sad.svg";

interface Props {
  happyText: string;
  unhappyText: string;
  isHappy: boolean | undefined;
  setIsHappy: (isHappy: boolean) => void;
  setIsHappyValid: (valid: boolean) => void;
}

const IsHappy = (props: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setIsHappyValid(true);
    if (e.target.value === "happy") {
      props.setIsHappy(true);
    } else if (e.target.value === "unhappy") {
      props.setIsHappy(false);
    }
  };
  return (
    <div className={classes.happyContainer}>
      <label className={classes.label}>
        <input
          className={classes.optionInput}
          type="radio"
          value="happy"
          name="isHappy"
          checked={props.isHappy === true}
          onChange={onChange}
        />
        <p className={classes.text}>{props.happyText}</p>
        <img
          alt="happy"
          src={smileyIcon}
          width={17}
          height={17}
          className={classes.smileIcon}
        ></img>
      </label>

      <label className={classes.label}>
        <input
          className={classes.optionInput}
          type="radio"
          value="unhappy"
          name="isHappy"
          checked={props.isHappy === false}
          onChange={onChange}
        />
        <p className={classes.text}>{props.unhappyText}</p>
        <img
          alt="unhappy"
          src={sadIcon}
          width={17}
          height={17}
          className={classes.smileIcon}
        ></img>
      </label>
    </div>
  );
};
export default IsHappy;
