import React, { ChangeEvent, ChangeEventHandler, useState } from "react";
import classes from "./text-field.module.css";

interface IValidationFunction {
  (text: string): boolean;
}

interface ITextFieldProps {
  title: string;
  placeHolder: string;
  isErrored: boolean;
  value: string;
  validationError: string;
  validationFn: IValidationFunction;
  setValue: Function;
}

export const TextField: React.FC<ITextFieldProps> = (
  props: ITextFieldProps
) => {
  const [validInput, setValidInput] = useState<boolean>(false);

  const valueChanges: ChangeEventHandler<HTMLInputElement> = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setValidInput(props.validationFn(props.value));
    props.setValue(event.target.value);
  };

  const textFieldClass = `${classes.textfieldInput} poppins`;

  return (
    <div>
      <p className={`${classes.title} poppins`}>{props.title}</p>
      {props.validationError && (
        <p className={`${classes.errorText} poppins`}>
          {props.validationError}
        </p>
      )}
      <input
        type="text"
        onChange={valueChanges}
        className={`${textFieldClass} ${
          !validInput
            ? classes.erroredTextFieldBorder
            : classes.correctTextFieldBorder
        }`}
        placeholder={props.placeHolder}
        value={props.value}
      />
    </div>
  );
};
