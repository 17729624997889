import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "./reducers/auth.reducer";
import { localizationReducer } from "./reducers/localization.reducer";
import { locationsReducer } from "./reducers/locations.reducer";
import { smileReducer } from "./reducers/smile.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  smile: smileReducer,
  locations: locationsReducer,
  localization: localizationReducer
});

export const store = createStore(rootReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof rootReducer>;
