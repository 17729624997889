import React from "react";

export const Loader: React.FC<{
  width: number;
  height: number;
  borderWidth: number;
}> = (props) => {
  return (
    <div
      className="spinner-border"
      style={{
        color: "var(--primary)",
        width: props.width,
        height: props.height,
        borderWidth: props.borderWidth,
      }}
      role="status"
    >
    </div>
  );
};
