import React from "react";
import classes from "./check-box.module.css";

interface Props {
  isChecked: boolean;
  setChecked: (checked: boolean) => void;
  label: string;
  id: string;
}

const CheckBox = (props: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setChecked(e.target.checked);
  };
  return (
    <div className={classes.checkBoxContainer}>
      <input
        className={classes.checkBox}
        type="checkbox"
        id={props.id}
        checked={props.isChecked}
        onChange={onChange}
        name={props.label}
        autoComplete="off"
      />
      <label className={classes.text} htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  );
};
export default CheckBox;
