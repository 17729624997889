import { UpdateInfoData } from "../../services/core/models/auth-response.model";
import { BaseLocationModel } from "../../services/core/models/base-location";

export const filterLocations = (
  query: string,
  filterField: string,
  locationsArray: any[]
) => {
  let filteredLocations = [...locationsArray];
  filteredLocations = locationsArray.filter(
    // eslint-disable-next-line
    (location) => location[filterField] == query
  );
  return filteredLocations;
};

export const getCustomerChosenCountry = (
  data: UpdateInfoData 
): BaseLocationModel | undefined => {
  const egyptMatchingStrs = ["eg", "egy", "egypt"];
  let chosenCountry: BaseLocationModel | undefined = undefined;
  const countries = data.countries;
  if (
    data.country &&
    egyptMatchingStrs.includes(data.country.value.toLowerCase())
  ) {
    chosenCountry = countries.filter((country) => country.name === "Egypt")[0];
  } else {
    if (data.country) {
      const countryArray = countries.filter(
        (country) => country.name === data.country.value
      );
      if (countries.length > 0) {
        chosenCountry = countryArray[0];
      }
    }
  }

  return chosenCountry;
};

export const getCustomerChosenLocationX = (
  chosenHigherLocation: BaseLocationModel,
  locationsToSearchIn: BaseLocationModel[],
  oldLocationName: string
): BaseLocationModel | undefined => {
  let chosenLocation: BaseLocationModel | undefined = undefined; 
    let locationArray = locationsToSearchIn.filter(
      (loc) =>
        loc.name === oldLocationName &&
        loc.parent_location === chosenHigherLocation?.id
    );
    if (locationArray.length === 1) {
      chosenLocation = locationArray[0];
    }

    return chosenLocation;
};
