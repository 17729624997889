import React, { MouseEvent, MouseEventHandler } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Header } from "../../components/header";
import { Loader } from "../../components/loading-spinner";
import { Smile } from "../../components/smile";
import { authUserAction } from "../../redux/actions/auth.actions";
import { changeLocale } from "../../redux/actions/localization.action";
import { smileAction } from "../../redux/actions/smile-actions";
import { selectTranslations } from "../../redux/reducers/localization.reducer";
import { SupportedLocales } from "../../redux/states/localization.state";
import { RootState } from "../../redux/store";
import {
  FeedbackData,
  UpdateInfoData,
} from "../../services/core/models/auth-response.model";
import classes from "./landing-page.module.css";

export const LandingPage: React.FC = () => {
  // used react hooks
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  let { error, loading, authResponse, token } = useSelector(
    (rootState: RootState) => rootState.auth
  );

  let { smile, description } = useSelector(
    (rootState: RootState) => rootState.smile
  );

  const pushTargetRoute = (locale: string) => {
    if (authResponse) {
      history.push(authResponse.route + `?lang=${locale}`);
    }
  };

  const onChooseArabicHandler: MouseEventHandler<HTMLButtonElement> = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    pushTargetRoute("ar");
    dispatch(changeLocale(SupportedLocales.ARABIC));
  };

  const onChooseEnglishHandler: MouseEventHandler<HTMLButtonElement> = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    pushTargetRoute("en");
    dispatch(changeLocale(SupportedLocales.ENGLISH));
  };

  useEffect(() => {
    if (!token) {
      dispatch(authUserAction(location.search));
    }
  }, [dispatch, location.search, token]);

  let t = useSelector(selectTranslations);

  useEffect(() => {
    if (authResponse) {
      if (authResponse.route === "update-info") {
        let data = authResponse.data as UpdateInfoData;
        let actionRequired =
          data.country.actionRequired ||
          data.city.actionRequired ||
          data.area.actionRequired ||
          data.address_line1.actionRequired;

        if (!actionRequired) {
          dispatch(smileAction(t.youAlreadyUploadedYourDataNotifier));
        }
      }
      if (authResponse.route === "feedback") {
        let data = authResponse.data as FeedbackData;
        if (data.submitted) {
          dispatch(smileAction("لقد قدمت لنا بالفعل تقييمك"));
        }
      }
    }
  }, [dispatch, authResponse, t.youAlreadyUploadedYourDataNotifier]);

  const failedToLoadDataElement = (
    <div
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p className={`${classes.chooseLangTitle} poppins-semi-bold`}>
        Failed To load data
      </p>
    </div>
  );

  return (
    <div>
      <Header />
      <div className={classes.bodyEnclosingContainer}>
        <div className={classes.bodyContainer}>
          {smile ? (
            <Smile description={description} />
          ) : loading ? (
            <Loader width={60} height={60} borderWidth={8} />
          ) : error ? (
            failedToLoadDataElement
          ) : (
            <div>
              <div style={{ textAlign: "center" }}>
                <p className={`${classes.chooseLangTitle} poppins-semi-bold`}>
                  Please choose your language
                </p>
              </div>

              <div className={classes.chooseLanguageContainer}>
                <div
                  style={{
                    display: "grid",
                    justifyContent: "end",
                    marginInlineEnd: "12px",
                  }}
                >
                  <button
                    onClick={onChooseEnglishHandler}
                    className={classes.chooseLangBtn}
                  >
                    <p className="poppins">English</p>
                  </button>
                </div>
                <div
                  style={{
                    display: "grid",
                    justifyContent: "start",
                    marginLeft: "12px",
                  }}
                >
                  <button
                    onClick={onChooseArabicHandler}
                    className={classes.chooseLangBtn}
                  >
                    <p className="cairo-font">العربية</p>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
