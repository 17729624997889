import { ThunkAction } from "redux-thunk";
import { SupportedLocales } from "../states/localization.state";
import { RootState } from "../store";
import { CHANGE_LOCALE, LocalizationAction } from "./localization-actions.interface";

export const changeLocale = (locale: SupportedLocales): ThunkAction<void, RootState, null, LocalizationAction> => {
    return async dispatch => {
        try {
            dispatch({
                type: CHANGE_LOCALE,
                locale: locale
            });
        } catch(e) {
            console.log(e);
        }
    }
}