import { ThunkAction } from "redux-thunk";
import { AuthResponseGenericModel, UpdateInfoData } from "../../services/core/models/auth-response.model";
import {
  UpdateInfoRequest,
  UpdateInfoResponse,
} from "../../services/core/models/update-info.model";
import { UpdateInfoRepository } from "../../services/data/web/repositories/update-info.repository";
import { WebAuthRepository } from "../../services/data/web/repositories/web-auth-repository";
import { RootState, store } from "../store";
import {
  AuthAction,
  AUTH_USER,
  UPDATE_ADDRESS,
} from "./auth-actions.interface";
import { smileAction } from "./smile-actions";

export const authUserAction = (
  token: string
): ThunkAction<void, RootState, null, AuthAction> => {
  return async (dispatch) => {
    try {
      dispatch({
        type: AUTH_USER,
        token: null,
        loading: true,
        error: false,
      });
      const authRepo = WebAuthRepository.getInstance();
      const authResponse: AuthResponseGenericModel =
        await authRepo.authenticateUser(token);
      dispatch({
        type: AUTH_USER,
        token: token,
        error: false,
        loading: false,
        authResponse: authResponse,
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: AUTH_USER,
        token: null,
        error: true,
        loading: false,
      });
    }
  };
};

export const updateAddress = (
  country: string,
  city: string,
  area: string,
  address: string
): ThunkAction<void, RootState, null, AuthAction> => {
  return async (dispatch) => {
    try {
      const updateInfoRepo = UpdateInfoRepository.getInstance();
      const updateInfoRequest = new UpdateInfoRequest(
        country,
        city,
        area,
        address
      );

      const updateInfoResponse: UpdateInfoResponse =
        await updateInfoRepo.updateInfo(updateInfoRequest);

      dispatch({
        type: UPDATE_ADDRESS,
        updateInfoData: updateInfoResponse,
      });

      const authResponse = store.getState().auth.authResponse;
      const data = authResponse?.data as UpdateInfoData | undefined

      const actionRequired =
        data?.country.actionRequired ||
        data?.city.actionRequired ||
        data?.area.actionRequired ||
        data?.address_line1.actionRequired;
      if (!actionRequired) {
        let locale = store.getState().localization.currentLocale;
        let t = store.getState().localization.translations[locale];
        dispatch(smileAction(t.yourDataIsSavedNotifier));
      }
    } catch (e) {
      console.log(e);
    }
  };
};
