import { ILocaleStrings } from "./locale-strings.interface";

export const englishLocaleStrings: ILocaleStrings = {
  wrongDataTitle: "Wrong Data",
  correctAddressDataHeader:
    "Please, correct the data highlighted in red for faster delivery.",
  countryInputHeader: "Country",
  cityInputHeader: "City",
  areaInputHeader: "Area",
  addressInputHeader: "Write your address in details",
  countryErrorText: "Please choose your country",
  cityErrorText: "Please choose your city",
  areaErrorText: "Please choose your area",
  addressErrorText: "Address has to be 3 or more words",
  submit: "Submit",
  orderNumber: "Order Number",
  brandName: "Brand Name",
  searchCountryPlaceHolder: "Search country",
  searchCityPlaceHolder: "Search city",
  searchAreaPlaceHolder: "Search area",
  countryInputPlaceHolder: "Choose your country...",
  cityInputPlaceHolder: "Choose your city...",
  areaInputPlaceHolder: "Choose your area",
  addressInputPlaceHolder: "Enter your address..",
  yourDataIsSavedNotifier: "Your data has been saved",
  couldntUpdateErrorText: "Could not update",
  noInternetConnectionErrorText: "Please, check your internet connection.",
  youAlreadyUploadedYourDataNotifier: "You already updated your data",
  fbTitle: "Give us your feedback",
  fbAreYouHappy: "Are you happy after purshasing from ",
  fbHappy: "Happy",
  fbUnhappy: "Unhappy",
  fbOrderIssues:
    "If you have any issues related with the order, please select here",
  fbCourierIssues:
    "If you have any issues related with the courier, please select here",
  fbOtherIssue: "Write here your issue please",
  fbSubmit: "Submit",
  fbSmileThanks: "Thanks for your feedback",
  fbSmileSubmitted: "You already gave us your feedback",
  fbModalTitle: "Could not submit feedback",
  fbModalInternet: "Please, check your internet connection.",
  fbErrorAreYouHappy: "Please select happy or unhappy",
  fbErrorSelectIssue: "Please select an issue",
  fbErrorOtherIssue: "Please type your issue",
  fbOther: "Other",

  /**
   * General
   */
  questionMark: "?",
};
