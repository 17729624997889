import React from "react";
import { FlextockLogo } from "../logo";
import './header.css';

export const Header: React.FC = () => {
  return (
    <div className="logo-container">
      <FlextockLogo height="26px" width="104px" />
    </div>
  );
};
