import {
  UpdateInfoRequest,
  UpdateInfoResponse,
} from "../../../core/models/update-info.model";
import { IUpdateInfoRepository } from "../../../core/repositories/update-info.repository";
import { WebClient } from "../web-client";

export class UpdateInfoRepository
  extends WebClient
  implements IUpdateInfoRepository
{
  private static _instance: UpdateInfoRepository;

  private constructor() {
    // singleton class
    super();
  }

  public static getInstance() {
    if (!this._instance) {
      this._instance = new UpdateInfoRepository();
    }
    return this._instance;
  }

  async updateInfo(
    updateInfoRequest: UpdateInfoRequest
  ): Promise<UpdateInfoResponse> {
    const response: UpdateInfoResponse = await this.instance.put(
      `customer/update/`,
      updateInfoRequest
    );
    return Promise.resolve(response);
  }
}
