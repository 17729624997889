import { useEffect, useState } from "react";
import { BackIcon } from "../../components/back-icon";
import { Header } from "../../components/header";
import classes from "./feedback.module.css";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { FeedbackRepository } from "../../services/data/web/repositories/feedback.repository";
import { FeedbackRequest } from "../../services/core/models/feedback.model";
import { smileAction } from "../../redux/actions/smile-actions";
import { selectTranslations } from "../../redux/reducers/localization.reducer";
import { SupportedLocales } from "../../redux/states/localization.state";
import { useHistory } from "react-router-dom";
import { Loader } from "../../components/loading-spinner";
import { CustomModal } from "../../components/modal";
import { FeedbackData } from "../../services/core/models/auth-response.model";
import CheckButton from "../../components/check-button";
import IsHappy from "./is-happy";
import CheckBox from "../../components/check-box";
import TextArea from "../../components/text-area";
import Error from "../../components/error";

export const Feedback: React.FC = () => {
  let { authResponse } = useSelector((rootState: RootState) => rootState.auth);

  let data = useSelector(
    (rootState: RootState) => rootState.auth.authResponse?.data
  ) as FeedbackData;

  let currentLocale = useSelector(
    (rootState: RootState) => rootState.localization.currentLocale
  );
  let t = useSelector(selectTranslations);

  const dispatch = useDispatch();
  const history = useHistory();
  const smile = useSelector((rootState: RootState) => rootState.smile.smile);

  useEffect(() => {
    if (data.submitted) {
      dispatch(smileAction(t.fbSmileSubmitted));
    }
  }, [data.submitted, dispatch, t.fbSmileSubmitted]);

  const [loading, setLoading] = useState<boolean>(false);
  const [modalMsg, setModalMsg] = useState("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  let orderIssuesInitialState: { [key: string]: boolean } = {};
  let courierIssuesInitialState: { [key: string]: boolean } = {};

  for (let i = 0; i < data.orderIssues.length; i++) {
    if (currentLocale === SupportedLocales.ARABIC) {
      const issue = data.orderIssues[i].issueAr;
      orderIssuesInitialState[issue] = false;
    } else {
      const issue = data.orderIssues[i].issueEn;
      orderIssuesInitialState[issue] = false;
    }
  }

  for (let i = 0; i < data.courierIssues.length; i++) {
    if (currentLocale === SupportedLocales.ARABIC) {
      const issue = data.courierIssues[i].issueAr;
      courierIssuesInitialState[issue] = false;
    } else {
      const issue = data.courierIssues[i].issueEn;
      courierIssuesInitialState[issue] = false;
    }
  }

  const [isHappy, setIsHappy] = useState<boolean>();
  const [hasOrderIssues, setHasOrderIssues] = useState<boolean>(false);
  const [hasCourierIssues, setHasCourierIssues] = useState<boolean>(false);
  const [orderIssues, setOrderIssues] = useState(orderIssuesInitialState);
  const [courierIssues, setCourierIssues] = useState(courierIssuesInitialState);
  const [otherOrderIssue, setOtherOrderIssue] = useState<string>("");
  const [otherCourierIssue, setOtherCourierIssue] = useState<string>("");

  const [isHappyValid, setIsHappyValid] = useState(true);
  const [orderIssueValid, setOrderIssueValid] = useState(true);
  const [courierIssueValid, setCourierIssueValid] = useState(true);
  const [orderOtherValid, setOrderOtherValid] = useState(true);
  const [courierOtherValid, setCourierOtherValid] = useState(true);

  const setCheckedOrderIssue = (name: string, checked: boolean) => {
    setOrderIssues({ ...orderIssues, [name]: checked });
    setOrderIssueValid(true);
  };

  const setCheckedCourierIssue = (name: string, checked: boolean) => {
    setCourierIssues({ ...courierIssues, [name]: checked });
    setCourierIssueValid(true);
  };

  const validateOrderIssues = (): boolean => {
    if (hasOrderIssues) {
      let valid = false;
      for (const key in orderIssues) {
        if (orderIssues[key]) {
          valid = true;
          break;
        }
      }
      if (!valid) {
        setOrderIssueValid(false);
        return false;
      }
    }
    setOrderIssueValid(true);
    return true;
  };

  const validateOtherOrderIssue = (): boolean => {
    if (hasOrderIssues && orderIssues[t.fbOther] && otherOrderIssue === "") {
      setOrderOtherValid(false);
      return false;
    }
    setOrderOtherValid(true);
    return true;
  };

  const validateCourierIssues = (): boolean => {
    if (hasCourierIssues) {
      let valid = false;
      for (const key in courierIssues) {
        if (courierIssues[key]) {
          valid = true;
          break;
        }
      }
      if (!valid) {
        setCourierIssueValid(false);
        return false;
      }
    }
    setCourierIssueValid(true);
    return true;
  };

  const validateOtherCourierIssue = (): boolean => {
    if (
      hasCourierIssues &&
      courierIssues[t.fbOther] &&
      otherCourierIssue === ""
    ) {
      setCourierOtherValid(false);
      return false;
    }
    setCourierOtherValid(true);
    return true;
  };

  const handleValidation = (): boolean => {
    let isValid = true;
    if (isHappy === undefined) {
      setIsHappyValid(false);
      isValid = false;
    }

    if (!validateOrderIssues()) isValid = false;

    if (!validateOtherOrderIssue()) isValid = false;

    if (!validateCourierIssues()) isValid = false;

    if (!validateOtherCourierIssue()) isValid = false;

    return isValid;
  };

  const onSubmit = async () => {
    if (!handleValidation()) {
      return;
    }
    setLoading(true);
    const updateInfoRepo = FeedbackRepository.getInstance();
    let orderIssuesReq = [];
    for (const key in orderIssues) {
      if (orderIssues[key]) {
        if (key === t.fbOther) {
          orderIssuesReq.push(otherOrderIssue);
        } else {
          orderIssuesReq.push(key);
        }
      }
    }
    let courierIssuesReq = [];
    for (const key in courierIssues) {
      if (courierIssues[key]) {
        if (key === t.fbOther) {
          courierIssuesReq.push(otherCourierIssue);
        } else {
          courierIssuesReq.push(key);
        }
      }
    }
    const updateInfoRequest = new FeedbackRequest(
      isHappy!,
      orderIssuesReq,
      courierIssuesReq
    );
    try {
      await updateInfoRepo.postFeedback(updateInfoRequest);
      setLoading(false);
      dispatch(smileAction(t.fbSmileThanks));
    } catch (error) {
      setLoading(false);
      if ((error as Error).message.includes("405")) {
        dispatch(smileAction(t.fbSmileSubmitted));
      } else {
        setModalMsg(t.fbModalInternet);
        setShowErrorModal(true);
      }
    }
  };

  const backClick = () => {
    history.goBack();
  };

  useEffect(() => {
    if (smile) {
      history.push("/");
    }
  }, [smile, history]);

  return (
    <div
      className="poppins"
      style={{
        direction: currentLocale === SupportedLocales.ARABIC ? "rtl" : "ltr",
      }}
    >
      <CustomModal
        title={t.fbModalTitle}
        body={modalMsg}
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
      />
      <Header />
      <div className={classes.flexContainer}>
        <div className={classes.titleDiv}>
          <div className={classes.backIconContainer} onClick={backClick}>
            <BackIcon width="16" height="16" />
          </div>
          <p className={`${classes.titleContainer} poppins-semi-bold`}>
            {t.fbTitle}
          </p>
        </div>
      </div>

      {loading ? (
        <div className={classes.loader}>
          <Loader width={60} height={60} borderWidth={8} />
        </div>
      ) : (
        <div className={classes.bodyContainer}>
          <div className={classes.merchantNameContainer}>
            <div className={classes.merchantName}>
              <p className={`${classes.merchantNameText}`}>
                {authResponse?.params.merchantName}
              </p>
            </div>
          </div>

          <p className={classes.text}>
            {t.fbAreYouHappy +
              authResponse?.params.merchantName +
              t.questionMark}
          </p>
          <IsHappy
            happyText={t.fbHappy}
            unhappyText={t.fbUnhappy}
            isHappy={isHappy}
            setIsHappy={setIsHappy}
            setIsHappyValid={setIsHappyValid}
          />
          {!isHappyValid && <Error message={t.fbErrorAreYouHappy} />}

          <CheckBox
            label={t.fbOrderIssues}
            id={"hasOrderIssues"}
            isChecked={hasOrderIssues}
            setChecked={setHasOrderIssues}
          />
          {hasOrderIssues && (
            <div className={classes.checkBtnGroup}>
              {Object.keys(orderIssues).map((key) => {
                return (
                  <CheckButton
                    handleChange={setCheckedOrderIssue}
                    isChecked={orderIssues[key]}
                    label={key}
                    id={"order-" + key}
                    key={"order-" + key}
                  />
                );
              })}
              {!orderIssueValid && <Error message={t.fbErrorSelectIssue} />}
            </div>
          )}

          {hasOrderIssues && orderIssues[t.fbOther] && (
            <div>
              <TextArea
                title={t.fbOtherIssue}
                value={otherOrderIssue}
                setValue={setOtherOrderIssue}
                setValidValue={setOrderOtherValid}
              />
              {hasOrderIssues && orderIssues[t.fbOther] && !orderOtherValid && (
                <div style={{ paddingInlineStart: "28px" }}>
                  <Error message={t.fbErrorOtherIssue} />
                </div>
              )}
            </div>
          )}

          <CheckBox
            label={t.fbCourierIssues}
            id={"hasCourierIssues"}
            isChecked={hasCourierIssues}
            setChecked={setHasCourierIssues}
          />
          {hasCourierIssues && (
            <div className={classes.checkBtnGroup}>
              {Object.keys(courierIssues).map((key) => {
                return (
                  <CheckButton
                    handleChange={setCheckedCourierIssue}
                    isChecked={courierIssues[key]}
                    label={key}
                    id={"courier-" + key}
                    key={"courier-" + key}
                  />
                );
              })}
              {!courierIssueValid && <Error message={t.fbErrorSelectIssue} />}
            </div>
          )}

          {hasCourierIssues && courierIssues[t.fbOther] && (
            <TextArea
              title={t.fbOtherIssue}
              value={otherCourierIssue}
              setValue={setOtherCourierIssue}
              setValidValue={setCourierOtherValid}
            />
          )}
          {hasCourierIssues && courierIssues[t.fbOther] && !courierOtherValid && (
            <div style={{ paddingInlineStart: "28px" }}>
              <Error message={t.fbErrorOtherIssue} />
            </div>
          )}

          <button
            type="button"
            className={classes.submitBtn}
            onClick={onSubmit}
          >
            {t.fbSubmit}
          </button>
        </div>
      )}
    </div>
  );
};
