import { ILocaleStrings } from "../../assets/locale-strings/locale-strings.interface";

export interface ILocalizationState {
    currentLocale: SupportedLocales;
    translations: TranslationsHolderObject;
}

export enum SupportedLocales {
    ENGLISH = 'en',
    ARABIC = 'ar'
}

export interface TranslationsHolderObject {
    ar: ILocaleStrings;
    en: ILocaleStrings;

    [key: string]: ILocaleStrings;
}