import { AuthAction, AUTH_USER, UPDATE_ADDRESS } from "../actions/auth-actions.interface";
import { IAuthState } from "../states/auth.state";

const intitialState: IAuthState = {
  token: null,
  error: false,
  loading: false,
  authResponse: undefined,
};

export const authReducer = (prevState = intitialState, action: AuthAction) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...prevState,
        token: action.token,
        error: action.error,
        loading: action.loading,
        authResponse: action.authResponse,
      };
    
    case UPDATE_ADDRESS: {
      let authResponse = prevState.authResponse;
      if(authResponse) {
        authResponse.data = {
          ...authResponse.data,
          country: action.updateInfoData.country,
          city: action.updateInfoData.city,
          area: action.updateInfoData.area,
          address_line1: action.updateInfoData.address_line1
        };
      }
      return {
        ...prevState,
        authResponse: authResponse
      }
    };
    default:
      return prevState;
  }
};
