import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { store } from "../../../redux/store";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class WebClient {
  private _baseURL = process.env.REACT_APP_CORE_URL || "http://localhost:8000";
  protected instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: this._baseURL,
    });
    this._initializeResponseInterceptor();
    this._initializeRequestInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(this._addAuthQueryParam);
  };

  private _addAuthQueryParam = (request: AxiosRequestConfig) => {
    
    let token = store.getState().auth.token;
    if (token) {
      request.url += `${token}`;
      // request.params = {
      //   ...request.params,
      //   token,
      // };
    }
    return request;
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  private _handleError = (error: AxiosError) => Promise.reject(error);
}
