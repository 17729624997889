import { IAuthRepository } from "../../../core/repositories/auth-repository.interface";
import { WebClient } from "../web-client";

export class WebAuthRepository extends WebClient implements IAuthRepository {

    private static _instance: WebAuthRepository;

    private constructor() {
        // singleton class
        super();
    }

    public static getInstance() {
        if(!this._instance) {
            this._instance = new WebAuthRepository();
        }
        return this._instance;
    }

    async authenticateUser(token: string): Promise<any> {
        const response = await this.instance.get(`customer/action/${token}`);
        return Promise.resolve(response);
    }
}