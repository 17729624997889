import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import { LandingPage } from "./pages/landing-page";
import { CorrectAddressInfo } from "./pages/correct-address-info";
import { Feedback } from "./pages/feedback";
import "bootstrap/dist/css/bootstrap.min.css";
import { PrivateRoute } from "./components/route-guards/private-route";

export const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <PrivateRoute path="/update-info" component={CorrectAddressInfo}>
        </PrivateRoute>
        <PrivateRoute path="/feedback" component={Feedback}>
        </PrivateRoute>
      </Switch>
    </Router>
  );
};
