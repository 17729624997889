import { ILocationsState } from "../states/locations.state";
import { GET_LOCATIONS, LocationsAction } from "./../actions/location-actions.interface";

const intitialState: ILocationsState = {
  baseLocations: [],
};

export const locationsReducer = (prevState = intitialState, action: LocationsAction) => {
    switch(action.type) {
        case GET_LOCATIONS: {
            return {
                ...prevState,
                baseLocations: action.baseLocations
            }
        }
        default: {
            return prevState;
        }
    }
}