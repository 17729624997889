import React from "react";
import classes from "./error.module.css";
import errorIcon from "./error.svg";

interface Props {
  message: string;
}

const Error = (props: Props) => {
  return (
    <div>
      <img className={classes.errorIcon} alt="error" src={errorIcon} width={12} height={12} />
      <p className={classes.errorText}>{props.message}</p>
    </div>
  );
};
export default Error;
