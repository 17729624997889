import { useEffect, useState } from "react";
import { DropDownInput } from "../../components/auto-complete-input";
import { BackIcon } from "../../components/back-icon";
import { Header } from "../../components/header";
import { TextField } from "../../components/text-field";
import classes from "./correct-address-info.module.css";
import { RootState, store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { UpdateInfoData } from "../../services/core/models/auth-response.model";
import { useHistory } from "react-router-dom";
import { Loader } from "../../components/loading-spinner";
import { CustomModal } from "../../components/modal";
import {
  filterLocations,
  getCustomerChosenCountry,
  getCustomerChosenLocationX,
} from "./utils";
import { BaseLocationModel } from "../../services/core/models/base-location";
import { selectTranslations } from "../../redux/reducers/localization.reducer";
import { SupportedLocales } from "../../redux/states/localization.state";
import { updateAddress } from "../../redux/actions/auth.actions";
import { Smile } from "../../components/smile";

export const CorrectAddressInfo: React.FC = () => {
  let { authResponse } = useSelector((rootState: RootState) => rootState.auth);
  let data = useSelector(
    (rootState: RootState) => rootState.auth.authResponse?.data
  ) as UpdateInfoData | undefined;

  const dispatch = useDispatch();
  const history = useHistory();
  const smile = useSelector((rootState: RootState) => rootState.smile.smile);

  const [loading, setLoading] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [country, setCountry] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [area, setArea] = useState<string>("");
  const [address, setAddress] = useState<string>("");

  const [countryError, setCountryError] = useState<string>("");
  const [cityError, setCityError] = useState<string>("");
  const [areaError, setAreaError] = useState<string>("");
  const [addressError, setAddressError] = useState<string>("");

  const handleValidation = (): boolean => {
    let validForm = true;

    if (!country) {
      setCountryError(t.countryErrorText);
      validForm = false;
    } else {
      setCountryError("");
    }
    if (!city) {
      setCityError(t.cityErrorText);
      validForm = false;
    } else {
      setCityError("");
    }
    if (!area) {
      setAreaError(t.areaErrorText);
      validForm = false;
    } else {
      setAreaError("");
    }
    if (!address || address.split(" ").length < 3) {
      setAddressError(t.addressErrorText);
      validForm = false;
    } else {
      setAddressError("");
    }

    return validForm;
  };

  const onSubmit = () => {
    if (!handleValidation()) {
      return;
    }

    setLoading(true);
    dispatch(updateAddress(country, city, area, address));

    // subscribe to changes
    store.subscribe(() => {
      if (store.getState().auth.error) {
        setShowErrorModal(true);
      }
      setLoading(false);
    });
  };

  const backClick = () => {
    history.goBack();
  };

  useEffect(() => {
    setAddress(data?.address_line1.value!);

    // track country city area for populating them in the dropdown inputs
    let chosenCountry: BaseLocationModel | undefined = undefined;
    let chosenCity: BaseLocationModel | undefined = undefined;
    let chosenArea: BaseLocationModel | undefined = undefined;

    chosenCountry = getCustomerChosenCountry(data!);

    // populate country data
    if (chosenCountry) {
      chooseCountry(chosenCountry);
      setCountry(chosenCountry.name);
    }

    // populate city if no action is required
    if (!data?.city.actionRequired) {
      chosenCity = getCustomerChosenLocationX(
        chosenCountry!,
        allCities,
        data?.city.value!
      );
    }

    if (chosenCity) {
      chooseCity(chosenCity);
      setCity(chosenCity.name);
    }

    if (!(data as UpdateInfoData).area.actionRequired) {
      chosenArea = getCustomerChosenLocationX(
        chosenCity!,
        allAreas,
        data?.area.value!
      );
    }

    if (chosenArea) {
      chooseArea(chosenArea);
      setArea(chosenArea.name);
    }

    // eslint-disable-next-line
  }, []);

  const countries = useSelector(
    (rootState: RootState) =>
      (rootState.auth.authResponse?.data as UpdateInfoData).countries!
  );

  const [cities, setCities] = useState<BaseLocationModel[]>([]);
  const [areas, setAreas] = useState<BaseLocationModel[]>([]);

  const allCities = useSelector(
    (rootState: RootState) =>
      (rootState.auth.authResponse?.data as UpdateInfoData).cities!
  );
  const allAreas = useSelector(
    (rootState: RootState) =>
      (rootState.auth.authResponse?.data as UpdateInfoData).areas!
  );

  const chooseCountry = (country: BaseLocationModel) => {
    if (country) {
      setCountry(country.name);
      setAreas([]);
      setArea("");
      setCity("");
      setCities(
        filterLocations(country.id.toString(), "parent_location", allCities)
      );
    }
  };

  const chooseCity = (city: BaseLocationModel) => {
    if (city) {
      setCity(city.name);
      setArea("");
      setAreas(
        filterLocations(city.id.toString(), "parent_location", allAreas)
      );
    }
  };

  const chooseArea = (area: BaseLocationModel) => {
    if (area) {
      setArea(area.name);
    }
  };

  const addressLineValidationFn = (text: string): boolean => {
    if (!text || text.split(" ").length < 3) {
      return false;
    }
    return true;
  };

  let currentLocale = useSelector(
    (rootState: RootState) => rootState.localization.currentLocale
  );

  // Translation object
  let t = useSelector(selectTranslations);

  return (
    <div
      style={{
        direction: currentLocale === SupportedLocales.ARABIC ? "rtl" : "ltr",
      }}
    >
      <CustomModal
        title={t.couldntUpdateErrorText}
        body={t.noInternetConnectionErrorText}
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
      />
      <Header />
      <div className={classes.flexContainer}>
        <div className={classes.titleDiv}>
          <div className={classes.backIconContainer} onClick={backClick}>
            <BackIcon width="16" height="16" />
          </div>
          <p className={`${classes.titleContainer} poppins-semi-bold`}>
            {t.wrongDataTitle}
          </p>
        </div>
      </div>

      <div className={classes.bodyEnclosingContainer}>
        <div className={classes.bodyContainer}>
          {smile ? (
            <Smile description={t.yourDataIsSavedNotifier} />
          ) : loading ? (
            <Loader width={60} height={60} borderWidth={8} />
          ) : (
            <div>
              <div style={{ padding: "16px" }}>
                <p
                  className={`${classes.correctAddressTitle} poppins-semi-bold`}
                >
                  {t.correctAddressDataHeader}
                </p>

                <div
                  className={classes.flexContainer}
                  style={{ marginTop: "24px" }}
                >
                  <div className={classes.orderDetailsContainer}>
                    <p className={`${classes.orderDetailTitle} poppins`}>
                      {t.orderNumber}
                    </p>
                    <div className={classes.orderDetailValueContainer}>
                      <p className={`${classes.orderDetailValueText} poppins`}>
                        {authResponse?.params.orderId}
                      </p>
                    </div>
                    <div className={classes.orderDetailContainer}>
                      <p className={`${classes.orderDetailTitle} poppins`}>
                        {t.brandName}
                      </p>
                    </div>
                    <div className={classes.orderDetailValueContainer}>
                      <p className={`${classes.orderDetailValueText} poppins`}>
                        {authResponse?.params.merchantName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ margin: "16px 0" }}
                className={classes.separator}
              ></div>

              <div className={classes.form}>
                <DropDownInput
                  dropdownSearchPlaceholder={t.searchCountryPlaceHolder}
                  placeHolder={t.countryInputPlaceHolder}
                  title={t.countryInputHeader}
                  isErrored={data?.country.actionRequired || false}
                  displayFieldName="name"
                  autoCompleteList={countries}
                  setValue={chooseCountry}
                  value={country}
                  validationError={countryError}
                />
                <DropDownInput
                  dropdownSearchPlaceholder={t.searchCityPlaceHolder}
                  placeHolder={t.cityInputPlaceHolder}
                  title={t.cityInputHeader}
                  isErrored={data?.city.actionRequired || false}
                  displayFieldName="name"
                  autoCompleteList={cities}
                  setValue={chooseCity}
                  value={city}
                  validationError={cityError}
                />
                <DropDownInput
                  dropdownSearchPlaceholder={t.searchAreaPlaceHolder}
                  placeHolder={t.areaInputPlaceHolder}
                  title={t.areaInputHeader}
                  isErrored={data?.area.actionRequired || false}
                  displayFieldName="name"
                  autoCompleteList={areas}
                  setValue={chooseArea}
                  value={area}
                  validationError={areaError}
                />
                <TextField
                  setValue={setAddress}
                  placeHolder={t.addressInputPlaceHolder}
                  title={t.addressInputHeader}
                  isErrored={data?.address_line1.actionRequired || false}
                  value={address}
                  validationError={addressError}
                  validationFn={addressLineValidationFn}
                />

                <div
                  style={{ marginTop: "20px" }}
                  className={classes.flexCenter}
                >
                  <button className={classes.submitBtn} onClick={onSubmit}>
                    <p
                      style={{ fontWeight: 800, margin: 0 }}
                      className="poppins"
                    >
                      {t.submit}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
