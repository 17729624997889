import React from "react";
import smileIcon from "./smile.svg";
import classes from './smile.module.css';

export interface ISmileProps {
  description: string;
}

export const Smile: React.FC<ISmileProps> = (props: ISmileProps) => {
  return (
    <div>
      <img
        alt="smile"
        src={smileIcon}
        width={102}
        height={102}
        className={classes.smileIcon}
      ></img>
      <p className={classes.description}>{props.description}</p>
    </div>
  );
};
