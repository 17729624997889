import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { SmileAction, SMILE } from "./smile-actions.interface";

export const smileAction = (
  description: string
): ThunkAction<void, RootState, null, SmileAction> => {
  return async (dispatch) => {
    dispatch({
      type: SMILE,
      smile: true,
      description,
    });
  };
};
