import React from "react";
import { useSelector } from "react-redux";
import { SupportedLocales } from "../../redux/states/localization.state";
import { RootState } from "../../redux/store";
import backIcon from "./back-icon.svg";

export interface IIconProps {
  width: string;
  height: string;
}

export const BackIcon: React.FC<IIconProps> = (props: IIconProps) => {

  let currentLocale = useSelector((rootState: RootState) => rootState.localization.currentLocale);

  return (
    <img
    style={{ transform: (currentLocale === SupportedLocales.ARABIC ? 'scale(-1,1)' : ''), cursor: 'pointer' }}
      alt="back_icon"
      src={backIcon}
      width={props.width}
      height={props.height}
    ></img>
  );
};
