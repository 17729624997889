import { AuthResponseGenericModel } from "../../services/core/models/auth-response.model";
import { UpdateInfoResponse } from "../../services/core/models/update-info.model";

export const AUTH_USER = 'AUTH USER';
export const UPDATE_ADDRESS = 'UPDATE ADDRESS';

interface IAuthUserAction {
    type: typeof AUTH_USER;
    token: string | null;
    error: boolean;
    loading?: boolean;
    authResponse?: AuthResponseGenericModel;
}

interface IUpdateAddressAction {
    type: typeof UPDATE_ADDRESS;
    updateInfoData: UpdateInfoResponse;
}

export type AuthAction = IAuthUserAction | IUpdateAddressAction;
