import {
  FeedbackRequest,
  FeedbackResponse,
} from "../../../core/models/feedback.model";
import { IFeedbackRepository } from "../../../core/repositories/feedback.repository";
import { WebClient } from "../web-client";

export class FeedbackRepository
  extends WebClient
  implements IFeedbackRepository
{
  private static _instance: FeedbackRepository;

  private constructor() {
    // singleton class
    super();
  }

  public static getInstance() {
    if (!this._instance) {
      this._instance = new FeedbackRepository();
    }
    return this._instance;
  }

  async postFeedback(
    feedbackRequest: FeedbackRequest
  ): Promise<FeedbackResponse> {
    const response: FeedbackResponse = await this.instance.post(
      `customer/feedback/`,
      feedbackRequest
    );
    return Promise.resolve(response);
  }
}
