import { arabicLocaleStrings } from "../../assets/locale-strings/arabic-locale-strings";
import { englishLocaleStrings } from "../../assets/locale-strings/english-locale-strings";
import {
  CHANGE_LOCALE,
  LocalizationAction,
} from "../actions/localization-actions.interface";
import {
  ILocalizationState,
  SupportedLocales,
} from "../states/localization.state";
import { RootState } from "../store";

const initialState: ILocalizationState = {
  currentLocale: SupportedLocales.ENGLISH,
  translations: {
    ar: arabicLocaleStrings,
    en: englishLocaleStrings,
  },
};

export const localizationReducer = (
  prevState = initialState,
  action: LocalizationAction
): ILocalizationState => {
  switch (action.type) {
    case CHANGE_LOCALE: {
      return {
        ...prevState,
        currentLocale: action.locale,
      };
    }
    default:
      return prevState;
  }
};

export const selectTranslations = (rootState: RootState) =>
  rootState.localization.translations[rootState.localization.currentLocale];
